import React, { useEffect } from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/NavbarStyleFour";
import Footer from "../components/_App/Footer";

// Shape Images
import Shape2 from "../assets/images/shape2.svg";
import Shape4 from "../assets/images/shape4.svg";
import Works from "../assets/json/works.json";
import { logPage } from "../analytics/analytics";

const Projects1 = () => {
    logPage("Portfolio | APPIUS - Software House & Web Agency");

    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 767) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", () => handleResize());
        handleResize();
        return window.removeEventListener("resize", () => handleResize());
    }, []);

    return (
        <Layout>
            <SEO title="Portfolio" />

            <Navbar />

            {/* <PageBanner pageTitle={"Alcuni dei nostri lavori".toUpperCase()} /> */}

            <div
                className="page-title-area overflow-hidden"
                style={{ alignContent: "center", textAlign: "center" }}
            >
                <div
                    className="container"
                    style={{ marginBottom: 50, textAlign: "start" }}
                >
                    <h2
                        style={{ color: "rgb(49, 48, 206)" }}
                        className="text-uppercase Varela container"
                    >
                        alcuni dei nostri lavori
                    </h2>
                </div>
                <div>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: isMobile
                                ? "auto"
                                : "auto auto",
                            columnWidth: isMobile ? "100%" : "50%",
                        }}
                    >
                        {Works.works.map((work) => {
                            return (
                                <div style={{ flex: 1 }}>
                                    <div
                                        className="single-works"
                                        style={{
                                            margin: 15,
                                            boxShadow:
                                                "rgba(0, 0, 0, 0.9) 0px 5px 15px",
                                        }}
                                    >
                                        <img
                                            src={
                                                require("../assets/images" +
                                                    work.img).default
                                            }
                                            alt="Immagine progetto"
                                            style={{
                                                objectFit: "cover",
                                                width: "100%",
                                            }}
                                        />
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                opacity: 0.3,
                                            }}
                                        />
                                        <div className="works-content1 text-start">
                                            <h3 style={{ color: "white" }}>
                                                {work.title}
                                            </h3>
                                            <p>{work.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                {/* Shape Images */}
                <div className="shape8 rotateme">
                    <img src={Shape2} alt="shape" />
                </div>
                <div className="shape2 rotateme">
                    <img src={Shape2} alt="shape" />
                </div>
                <div className="shape7">
                    <img src={Shape4} alt="shape" />
                </div>
                <div className="shape4">
                    <img src={Shape4} alt="shape" />
                </div>
            </div>

            <Footer />
        </Layout>
    );
};

export default Projects1;
